<template>
    <el-table v-if="dataOrigin.length > 0" v-loading="$wait.is('loading')" :data="data" element-loading-spinner="el-custom-spinner">
        <el-table-column :label="$t('managers.name')" prop="name" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/managers/${scope.row.uuid}/general`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.name }} {{ scope.row.surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('managers.email')" prop="email" sortable />
        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link :to="`/managers/${scope.row.uuid}/general`" class="el-button el-tooltip el-button--primary el-button--mini">
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.archive')" placement="top">
                        <el-button type="warning" size="mini" :disabled="!userCan('archive managers')" @click="handleArchive(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'archive']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type:    Array,
            default: () => [],
        },

        dataOrigin: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleArchive(uuid) {
            this.$emit('handleArchive', uuid);
        },
    },
};
</script>
